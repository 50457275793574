import React, { Fragment, useEffect } from "react";

import { navigate } from "@utils/navigate";
import { createDriveView, ViewComponent } from "@drive/view";

import { useAuth } from "@api/auth";

import { isProductEnabled } from "@utils/products";

import { ProductType } from "@api/products";
import { ProductCtaCard } from "~/components/ProductCta/Card";

const Drive: ViewComponent = () => {
	const { selectedCompany } = useAuth();
	const isDriveToolEnabled = isProductEnabled(
		selectedCompany,
		ProductType.DriveTool,
	);

	useEffect(() => {
		if (isDriveToolEnabled) {
			navigate("/drive/upcoming", {
				replace: true,
			});
		}
	}, [isDriveToolEnabled]);

	return !isDriveToolEnabled ? (
		<ProductCtaCard style={{ margin: "20px" }} topic="DRIVE_TOOL" />
	) : null;
};

export default createDriveView(Drive, {
	title: "Test Drive",
	internalScroll: true,
	internalPad: true,
});
